import React from "react";

import ContactDetails from "../components/ContactDetails.js";

function Contact() {
  return (
    <div className="contact">
      <ContactDetails />
    </div>
  );
}

export default Contact;
