import React from "react";

function subParagraph(props) {
  const data = props.block;
  const listItem = data.listItem;

  return (
    <section className={"subParagraph " + data._uid}>
      <div className="container">
        <div className="row">
          <div className="offset-lg-2 offset-md-2 col-lg-8 col-md-8 col-sm-12 col-xs-12">
            <div className="sub-paragraph__text-block" key={data.id}>

              {data.title ? (
                <h3 className="sub-paragraph__title">{data.title}</h3>
              ) : null}

              <span className="sub-paragraph__text">{data.text} </span>
            </div>

            {listItem && listItem.length > 0 && (
              <ol className="list-item">
                {listItem.map((item, i) =>
                  item ? (
                    <li key={item.id}>{item.item}</li>
                  ) : null
                )}
              </ol>
            )}

          </div>
        </div>
      </div>
    </section >

  );
}

export default subParagraph;
