import React from "react";
import ContactDetails from "../components/ContactDetails.js";
import { Link } from "react-router-dom";

function NewTemplate() {
  return (
    <div className="new-template">

      <section className="title-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-7 col-xs-12">
              <span className="title-banner__breadcrumbs">
                <Link to="/case-studies">Case Studies</Link> &gt; User-Centered Discovery
              </span>
              <span className="title-banner__accent">
                JOHN LEWIS &amp; PARTNERS
              </span>
              <h1 className="title-banner__title">User-Centered Discovery in to customer content</h1>
              <span className="title-banner__date">August 2023</span>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-5 col-xs-12">
              <div className="d-flex h-100">
                <img className="title-banner__img " src="http://duransearle.co.uk/img/case-studies/john-lewis-discovery/John-Lewis-and-Partners-Oxford-Street.jpg" alt="text" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="paragraph">
        <div className="container">
          <div className="row">
            <div className="offset-lg-2 offset-md-2 col-lg-8 col-md-8 col-sm-12 col-xs-12">
              <div className="paragraph__text-block">
                <h2 className="paragraph__title">Introduction</h2>
                <span className="paragraph__text">
                  As the senior UX designer in the Fashion Product team at John Lewis, my primary focus was the users' experience on the Fashion Product Page. However, I was also responsible for the reviews and user-generated content journeys.
                  <br />
                  <br />
                  As a team, We knew that user-generated content, such as ratings, reviews and social media images, played a big part in users' online shopping journey - but we wanted to understand more about the who, what, and why behind them.
                  <br />
                  <br />
                  In Q3 of 2022, I, along with a core team of Product Owner Rhiannon Hughes, UI Design Manager Katrina Joseph and Senior User Researcher Richie Kennedy, initiated a user-centred discovery to identify areas of opportunity and improvement within our reviews and user-generated content journeys.
                  <br />
                  <br />
                  Our ultimate goal was to create a tangible backlog of ideas that prioritised user outcomes.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="image__triple">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="d-flex h-100">
                <img className="image__img--one"
                  src="http://duransearle.co.uk/img/case-studies/john-lewis-discovery/John-Lewis-and-Partners-Oxford-Street.jpg"
                  alt="John Lewis & Partners"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <img className="image__img--two"
                src="http://duransearle.co.uk/img/case-studies/john-lewis-discovery/John-Lewis-and-Partners-Oxford-Street.jpg"
                alt="John Lewis & Partners"
              />

              <img className="image__img--three"
                src="http://duransearle.co.uk/img/case-studies/john-lewis-discovery/John-Lewis-and-Partners-Oxford-Street.jpg"
                alt="John Lewis & Partners"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="paragraph">
        <div className="container">
          <div className="row">
            <div className="offset-lg-2 offset-md-2 col-lg-8 col-md-8 col-sm-12 col-xs-12">
              <div className="paragraph__text-block">
                <h2 className="paragraph__title">Problem Statement</h2>
                <span className="paragraph__text">
                  We knew that reviews and UGC (user-generated content) played a big part in users' online shopping journey, but our understanding needed to be improved.
                  <br />
                  <br />
                  Given the team's other priorities, we hadn't been able to investigate what users looked for in a review, why customers left reviews, and how vital peer-to-peer content was within a shopping journey.
                  <br />
                  <br />
                  This project was an excellent opportunity to explore and test our assumptions.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="hypothesis">
        <div className="container">
          <div className="row">
            <div className="offset-lg-2 offset-md-2 col-lg-8 col-md-8 col-sm-12 col-xs-12">
              <div className="hypothesis__text-block">
                <h2 className="hypothesis__title">Our hypothesis</h2>
                <span className="hypothesis__text">
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="paragraph">
        <div className="container">
          <div className="row">
            <div className="offset-lg-2 offset-md-2 col-lg-8 col-md-8 col-sm-12 col-xs-12">
              <div className="paragraph__text-block">
                <span className="paragraph__text">
                  We used the double-diamond approach to user-centred design, as the four stages of Discover, Define, Develop, and Deliver would allow us to explore problems broadly before refining solutions focused on the user needs, resulting in a more effective and user-centric design process.
                  <br />
                  <br />
                  This process would give us greater confidence in developing the right features for our users and help us prioritise and shape the product roadmap.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="image__single">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <img className="image__img"
                src="http://duransearle.co.uk/img/case-studies/john-lewis-discovery/John-Lewis-and-Partners-Oxford-Street.jpg"
                alt="John Lewis & Partners"
              />
            </div>
          </div>
        </div>
      </section>



      <section className="paragraph__center">
        <div className="container">
          <div className="row">
            <div className="offset-lg-2 offset-md-2 col-lg-8 col-md-8 col-sm-12 col-xs-12">
              <div className="paragraph__text-block">
                <h2 className="paragraph__title">Discover</h2>
                <span className="paragraph__text">
                  The discovery phase focuses on user engagement with Reviews, UGC, and Social Proofing in its current state.
                  <br />
                  <br />
                  We explored user interactions, behaviours and benchmarking through Competitor Analysis, Existing Research, Data Analysis, and a Touchpoint Inventory Workshop.
                  <br />
                  <br />
                  The goal was to explore the existing experiences, gathering extensive data and information to inform the subsequent stages of the design process.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ContactDetails />
    </div >
  );
}

export default NewTemplate;
