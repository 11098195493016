import React, { useEffect, useState } from "react";
import Components from "../components/Components.js";
import ContactDetails from "../components/ContactDetails.js";
import { Link, useParams } from "react-router-dom";
import PasswordPrompt from '../components/case-study/password-prompt/PasswordPrompt.js'; // Adjust the path as needed

function CaseStudy() {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const isAuthenticated = localStorage.getItem('authenticated') === 'true';
    setAuthenticated(isAuthenticated);
    if (!isAuthenticated) {
      document.body.classList.add('password-prompt-visible');
    }
  }, []);

  const handleAuthenticated = () => {
    setAuthenticated(true);
    document.body.classList.remove('password-prompt-visible');
  };

  const params = useParams();
  const data = require(`../data/${params.id}.json`);

  // Check if there are at least two blocks to preview
  const previewBlocks = data.slice(0, 2);
  const remainingBlocks = data.slice(2);

  return (
    <div className={"case-study " + params.id}>
      {previewBlocks.map((block) => Components(block))}

      {!authenticated && <PasswordPrompt onAuthenticated={handleAuthenticated} fullWidth />}

      {authenticated && (
        <>
          {remainingBlocks.map((block) => Components(block))}

          <section className="case-study__credits">
            <div className="container">
              <div className="row">
                <div className="offset-lg-2 offset-md-2 col-lg-8 col-md-8 col-sm-12 col-xs-12">
                  Some images on this page are from unsplash.<br />If you would like to see the photographers credits &nbsp;
                  <Link to="/credits">click here</Link>
                </div>
              </div>
            </div>
          </section>

          <ContactDetails />
        </>
      )}
    </div>
  );
}

export default CaseStudy;
