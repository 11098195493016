import React from "react";

function rightSide(props) {
  const data = props.block;
  return (
    <section className={"stage rightSide " + data._uid}>
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-md-5 col-sm-12 order-2 order-md-1 d-flex align-items-center">
            <img
              className="rightSide__image"
              src={data.image}
              alt={data.title}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 order-1 order-md-2 offset-md-1">
            <div className="rightSide__text-block">
              <div className="rightSide__title">{data.title}</div>
              <div className="rightSide__text">{data.text}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default rightSide;
