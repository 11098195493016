import React from "react";

import headerBanner from "../components/case-study/header-banner/headerBanner.js";
import projectInfo from "../components/case-study/project-info/projectInfo.js";
import rightSide from "../components/case-study/right-side/rightSide.js";
import fullWidth from "../components/case-study/full-width/fullWidth.js";
import leftSide from "../components/case-study/left-side/leftSide.js";
import dual from "../components/case-study/dual/dual.js";
import imageGrid from "../components/case-study/image-grid/imageGrid.js";

import ArgosResult from "../components/case-study/argos-result/ArgosResult.js";

import titleBanner from "../components/case-study/title-banner/titleBanner.js";
import paragraph from "../components/case-study/paragraph/paragraph.js";
import subParagraph from "../components/case-study/sub-paragraph/subParagraph.js";
import hypothesis from "../components/case-study/hypothesis/hypothesis.js";
import imageSingle from "../components/case-study/image-single/imageSingle.js";
import imageTriple from "../components/case-study/image-triple/imageTriple.js";

const Components = {
  headerBanner: headerBanner,
  projectInfo: projectInfo,

  leftSide: leftSide,
  fullWidth: fullWidth,
  rightSide: rightSide,
  dual: dual,
  imageGrid: imageGrid,

  ArgosResult: ArgosResult,

  titleBanner: titleBanner,
  paragraph: paragraph,
  subParagraph: subParagraph,
  hypothesis: hypothesis,
  imageSingle: imageSingle,
  imageTriple: imageTriple,

};

export default (block) => {
  if (typeof Components[block.component] !== "undefined") {
    return React.createElement(Components[block.component], {
      key: block._uid,
      block: block,
    });
  }
  return React.createElement(
    () => <div>The component {block.component} has not been created yet.</div>,
    { key: block._uid }
  );
};
