import React, { useEffect } from "react";

function Credits() {
  const CreditsList = require("../data/credits.json");
  console.log(CreditsList);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="credits">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <span className="credits__title">Credits</span>
          </div>
        </div>
        <div className="row">
          {CreditsList.map((PhotoInfo, index) => {
            return (
              <div className="col-lg-4 col-md-6 col-sm-12" key={PhotoInfo.key}>
                <a
                  href={PhotoInfo.imageLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="credits__image"
                    src={PhotoInfo.image}
                    alt={PhotoInfo.photographer}
                  />
                </a>
                <div className="credits__text-block">
                  <div className="credits__desc">{PhotoInfo.description}</div>
                  <div className="credits__photographer">
                    Photo by &nbsp;
                    <a
                      href={PhotoInfo.imageLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {PhotoInfo.photographer}
                    </a>
                  </div>
                  <div className="credits__unsplash">
                    As see on &nbsp;
                    <a
                      className="credits__link"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
                    >
                      Unsplash
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default Credits;
