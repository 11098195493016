import React from "react";
import ContactDetails from "../components/ContactDetails.js";
import { Link } from "react-router-dom";

function Home() {
  return (
    <div className="home">
      <section className="header-banner">
        <div className="container d-flex h-100">
          <div className="row align-self-center">
            <div className="col-lg-6 col-md-8 col-sm-12 col-xs-12">
              <div className="text-block">
                <span className="text-block__accent">Duran Searle</span>
                <h1 className="text-block__title">UX/ UI Designer</h1>
                <span className="text-block__text">
                  I'm a user experience and web designer with over 15 years e-commerce experience. I've been lucky enough to work at some of the UK's largest retail companies across many industries, including Marks and Spencer, Argos, and, most recently, John Lewis &amp; Partners.
                  <br />
                  <br />
                  Whilst at John Lewis &amp; Partners I worked across different product teams to delivery improvements throughout the website &amp; App including Fashion, Customer Content and PDP.
                </span>
                <Link className="cta--secondary" to="/career">
                  Read More
                </Link>
                <Link className="cta--primary" to="/contact">
                  Get in touch
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="header-banner__overlay"></div>
      </section>

      <section className="stage">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 order-2 order-md-1 d-flex align-items-center">
              <div className="text-block">
                <span className="text-block__accent">
                  Case Study - John Lewis & Partners
                </span>
                <h1 className="text-block__title">User-centered discovery in to customer content</h1>
                <span className="text-block__text">
                  Identifing areas of opportunity and improvement within the reviews and user-generated content journeys.
                </span>
                <Link
                  className="cta--secondary"
                  to="/case-studies/john-lewis-discovery"
                >
                  Read More
                </Link>
              </div>
            </div>

            <div className="offset-md-1 col-lg-6 col-md-6 col-sm-12 col-xs-12 order-1 order-md-2 cover">
              <Link to="/case-studies/john-lewis-discovery">
                <img
                  src="https://duransearle.co.uk/img/case-studies/john-lewis-discovery/John-Lewis-and-Partners-Oxford-Street.jpg"
                  alt="Case Study - John Lewis & Partners"
                />
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="logo-list">
        <div className="container">
          <div className="row">
            <div className="logo-list__title">Companies I've worked with</div>
            <div className="logo-list__text">
              Over the last 15 years, I've been lucky enough to work with some
              of the UK's largest retailers including;
              <br />John Lewis &amp; Partners, Marks &amp; Spencer and Argos.
            </div>
            <ul className="logo-list__list">
              <li className="logo-list__item">
                <img
                  className="logo-list__logo"
                  src="http://duransearle.co.uk/img/logos/john-lewis_logo.png"
                  alt="John Lewis & Partners"
                />
              </li>
              <li className="logo-list__item">
                <img
                  className="logo-list__logo"
                  src="http://duransearle.co.uk/img/logos/doctify_logo.png"
                  alt="Doctify Ltd"
                />
              </li>
              <li className="logo-list__item">
                <img
                  className="logo-list__logo"
                  src="http://duransearle.co.uk/img/logos/marks_logo.png"
                  alt="Marks & Spencers"
                />
              </li>
              <li className="logo-list__item">
                <img
                  className="logo-list__logo"
                  src="http://duransearle.co.uk/img/logos/argos_logo.png"
                  alt="Argos"
                />
              </li>
              <li className="logo-list__item">
                <img
                  className="logo-list__logo"
                  src="http://duransearle.co.uk/img/logos/wiggle_logo.png"
                  alt="Wiggle"
                />
              </li>
              <li className="logo-list__item">
                <img
                  className="logo-list__logo"
                  src="http://duransearle.co.uk/img/logos/planet_logo.png"
                  alt="Planet Cruise"
                />
              </li>
              <li className="logo-list__item">
                <img
                  className="logo-list__logo"
                  src="http://duransearle.co.uk/img/logos/iglu_logo.png"
                  alt="Iglu Ski"
                />
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="stage">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <Link to="/case-studies/argos-hotspot-component">
                <div className="hotspot--one">
                  <div className="hotspot__btn">
                    <i className="fas fa-info hotspot__icon"></i>
                  </div>
                  <div className="hotspot__bg"></div>
                </div>
                <div className="hotspot--two">
                  <div className="hotspot__btn">
                    <i className="fas fa-info hotspot__icon"></i>
                  </div>
                  <div className="hotspot__bg"></div>
                </div>
                <div className="hotspot--three">
                  <div className="hotspot__btn">
                    <i className="fas fa-info hotspot__icon"></i>
                  </div>
                  <div className="hotspot__bg"></div>
                </div>
                <div className="hotspot--four">
                  <div className="hotspot__btn">
                    <i className="fas fa-info hotspot__icon"></i>
                  </div>
                  <div className="hotspot__bg"></div>
                </div>

                <img
                  src="http://duransearle.co.uk/img/case-studies/argos-hotspot-component/argos-hotspot-component__header-banner.jpg"
                  alt="argos"
                />
              </Link>
            </div>
            <div className="offset-md-1 col-lg-5 col-md-5 col-sm-12 col-xs-12 d-flex align-items-center">
              <div className="text-block">
                <span className="text-block__accent">
                  Case Study - Sainsbury's Argos
                </span>
                <h1 className="text-block__title">Hotspot Component</h1>
                <span className="text-block__text">
                  The purpose of this component is to make gorgeous photography
                  shop-able in an engaging and inspiring way, allowing potential
                  customers to access all the information they need about that
                  given product without leaving the page.
                </span>
                <Link
                  className="cta--secondary"
                  to="/case-studies/argos-hotspot-component"
                >
                  Read More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ContactDetails />
    </div>
  );
}

export default Home;
