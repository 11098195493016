import React from "react";

function imageSingle(props) {
  const data = props.block;
  return (
    <section className={"image__single " + data._uid}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <img className="image__img"
              src={data.src}
              alt={data.alt}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default imageSingle;
