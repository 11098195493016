import React from "react";
import { NavLink } from "react-router-dom";

function Header(props) {
  return (
    <header className="header">
      <div className="container">
        <nav className="nav" role="navigation">
          <NavLink to="/" className="nav__logo">
            Duran Searle
          </NavLink>
          <div className="nav__list">
            <ul className="d-none d-md-block">
              <li className="nav__item">
                <NavLink to="/career" className="nav__link">
                  Career
                </NavLink>
              </li>
              <li className="nav__item">
                <NavLink to="/case-studies" className="nav__link">
                  Case Studies
                </NavLink>
              </li>
              <li className="nav__item">
                <NavLink to="/contact" className="nav__link">
                  Contact
                </NavLink>
              </li>
            </ul>
            <div className="nav__menu d-md-none">
              <div className="nav__mobile-button" onClick={props.click}>
                <i className="fas fa-bars"></i>
                <span>Menu</span>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;
