import React from "react";

function hypothesis(props) {
  const data = props.block;
  return (

    <section className={"hypothesis " + data._uid}>
      <div className="container">
        <div className="row">
          <div className="offset-lg-2 offset-md-2 col-lg-8 col-md-8 col-sm-12 col-xs-12">
            <div className="hypothesis__text-block">
              <h2 className="hypothesis__title">{data.title}</h2>
              <span className="hypothesis__text"><b>Due to </b>{data.dueTo}</span>
              <span className="hypothesis__text"><b>We believe </b>{data.weBelieve}</span>
              <span className="hypothesis__text"><b>Will result in </b>{data.willResultIn}</span>
              <span className="hypothesis__text"><b>We'll know we're successful when;</b>
                <br />
                <br />
                {data.success}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default hypothesis;
