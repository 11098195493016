import React, { useState } from "react";
import ArgosSideDrawers from "./ArgosSideDrawer.js";

import Overlay from "../../overlay/Overlay.js";

function ArgosResult(props) {
  const data = props.block;

  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  const drawerToggleClickHandler = () => setSideDrawerOpen(!sideDrawerOpen);
  const backdropClickHandler = () => setSideDrawerOpen(false);

  return (
    <section className={"stage argosResult " + data._uid}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="hotspot--one" onClick={drawerToggleClickHandler}>
              <div className="hotspot__btn">
                <i className="fas fa-info hotspot__icon"></i>
              </div>
              <div className="hotspot__bg"></div>
            </div>
            <div className="hotspot--two" onClick={drawerToggleClickHandler}>
              <div className="hotspot__btn">
                <i className="fas fa-info hotspot__icon"></i>
              </div>
              <div className="hotspot__bg"></div>
            </div>
            <div className="hotspot--three" onClick={drawerToggleClickHandler}>
              <div className="hotspot__btn">
                <i className="fas fa-info hotspot__icon"></i>
              </div>
              <div className="hotspot__bg"></div>
            </div>
            <div className="hotspot--four" onClick={drawerToggleClickHandler}>
              <div className="hotspot__btn">
                <i className="fas fa-info hotspot__icon"></i>
              </div>
              <div className="hotspot__bg"></div>
            </div>
            <img
              src="http://duransearle.co.uk/img/case-studies/argos-hotspot-component/argos-hotspot-component__header-banner.jpg"
              alt="argos"
            />
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <img
              className="d-none d-lg-block"
              src="http://duransearle.co.uk/img/case-studies/argos-hotspot-component/argos-hotspot-component__hotspot-banner.jpg"
              alt="argos"
            />
            <div className="hotspot__text-block">
              <div className="hotspot__title">Hard anodised cookware</div>
              <div className="hotspot__text">
                Highly durable, dishwasher safe cookware that cooks food quickly
                and evenly whilst being very non-stick. Choose from pots and
                pans in a variety of shapes and sizes to complement your
                cookware collection.
              </div>
            </div>
          </div>
        </div>
      </div>
      <ArgosSideDrawers
        show={sideDrawerOpen}
        click={drawerToggleClickHandler}
      />
      {sideDrawerOpen && <Overlay click={backdropClickHandler} />}
    </section>
  );
}

export default ArgosResult;
