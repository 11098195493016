import React, { useEffect } from "react";

function Error() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="error">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">blas</div>
        </div>
      </div>
    </section>
  );
}

export default Error;
