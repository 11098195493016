import React from "react";

function leftSide(props) {
  const data = props.block;
  return (
    <section className={"stage leftSide " + data._uid}>
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-md-5 col-sm-12 d-flex align-items-center">
            <div className="leftSide__text-block">
              <div className="leftSide__title" dangerouslySetInnerHTML={{ __html: data.title }}></div>
              <div className="leftSide__text" dangerouslySetInnerHTML={{ __html: data.text }}></div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 offset-md-1">
            <img
              className="leftSide__image"
              src={data.image}
              alt={data.title}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default leftSide;
