import React from "react";

function titleBanner(props) {
  const data = props.block;
  return (
    <section className={"title-banner " + data._uid}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-7 col-xs-12">
            <span className="title-banner__breadcrumbs">
              <a
                href="/#/case-studies"
              >
                Case Studies
              </a> &gt; {data.shortTitle}
            </span>
            <span className="title-banner__accent">{data.client}</span>
            <h1 className="title-banner__title">{data.title}</h1>
            <span className="title-banner__read-time">{data.readTime}</span>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-5 col-xs-12">
            <div className="d-flex h-100">
              <img
                className="title-banner__img"
                src={data.image}
                alt={data.title}
              />
            </div>
          </div>
        </div>
      </div>
    </section>




  );
}

export default titleBanner;
