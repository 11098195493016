import React from "react";
import Gallery from "react-grid-gallery";

function imageGrid(props) {
  const data = props.block;
  return (
    <section className="imageGrid ">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <Gallery
              images={data.images}
              enableImageSelection={false}
              rowHeight={180}
            />
            ,
          </div>
        </div>
      </div>
    </section>
  );
}

export default imageGrid;
