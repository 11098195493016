import React from "react";

function projectInfo(props) {
  const data = props.block;
  return (
    <section className={"stage projectInfo " + data._uid}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-5 col-sm-5 col-xs-6 d-flex align-items-center">
            <div className="info">
              <ul className="info__group">
                <li className="info__key">Client</li>
                <li className="info__value">{data.client}</li>
              </ul>
              <ul className="info__group">
                <li className="info__key">Sector</li>
                <li className="info__value">{data.sector}</li>
              </ul>
              <ul className="info__group">
                <li className="info__key">Project Management</li>
                <li className="info__value">{data.projectManagement}</li>
              </ul>
              <ul className="info__group">
                <li className="info__key">Year</li>
                <li className="info__value">{data.year}</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 col-md-7 col-sm-7 col-xs-6 d-flex align-items-center">
            <div className="projectInfo__text-block">
              <div className="projectInfo__title">{data.title}</div>
              <div className="projectInfo__text">{data.text}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default projectInfo;
