import React, { useRef, useEffect } from "react";
import { TimelineLite } from "gsap";

function HeroBanner(props) {
  const data = props;

  const tl = new TimelineLite();
  const cover = useRef(null);
  const img = useRef(null);

  useEffect(() => {
    tl.from(cover.current, 0.3, {
      scaleX: 0,
      transformOrigin: "left",
    });
    tl.to(
      cover.current,
      0.5,
      { scaleX: 0, transformOrigin: "right" },
      "reveal"
    );
  });

  return (
    <section className="HeroBanner">
      <img className="img-animation" src={data.background} alt={data.heading} />

      <div className="container d-flex h-100">
        <div className="row align-self-center">
          <div className="text-block">
            <h1>{data.heading}</h1>
            <i className="fas fa-chevron-down chevron__icon"></i>
          </div>
        </div>
      </div>
      <div className="HeroBanner__overlay"></div>
    </section>
  );
}

export default HeroBanner;
