import React from "react";

function dual(props) {
  const data = props.block;
  return (
    <section className={"stage dual " + data._uid}>
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12">
            <div className="row">
              <div className="col-12 order-2 order-md-1">
                {data.imageLeft ? (
                  <img
                    className="dual__image"
                    src={data.imageLeft}
                    alt={data.titleLeft}
                  />
                ) : null}
              </div>
              <div className="col-12 order-1 order-md-2">
                <div className="dual__text-block">
                  <div className="dual__title">{data.titleLeft}</div>
                  <div className="dual__text">{data.textLeft}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 offset-lg-1">
            <div className="row">
              <div className="col-12 order-2 order-md-1">
                {data.imageRight ? (
                  <img
                    className="dual__image"
                    src={data.imageRight}
                    alt={data.titleRight}
                  />
                ) : null}
              </div>
              <div className="col-12 order-1 order-md-2">
                <div className="dual__text-block">
                  <div className="dual__title">{data.titleRight}</div>
                  <div className="dual__text">{data.textRight}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default dual;
