import React, { useState } from "react";
import { Switch, Route, HashRouter } from "react-router-dom";

import Status from "./components/status/Status.js";
import Header from "./components/Header.js";
import Footer from "./components/Footer.js";
import SideDrawer from "./components/SideDrawer.js";
import Overlay from "./components/overlay/Overlay.js";

import Home from "./pages/Home.js";
import About from "./pages/About.js";

import CaseStudies from "./pages/CaseStudies.js";
import CaseStudy from "./pages/CaseStudy.js";
import Contact from "./pages/Contact.js";
import Credits from "./pages/Credits.js";
import Error from "./pages/Error.js";
import NewTemplate from "./pages/NewTemplate.js";

import "./App.scss";

function App() {
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  const drawerToggleClickHandler = () => setSideDrawerOpen(!sideDrawerOpen);
  const backdropClickHandler = () => setSideDrawerOpen(false);

  return (
    <HashRouter history={HashRouter}>
      <div className="App">
        <Status />
        <Header click={drawerToggleClickHandler} />
        {sideDrawerOpen && <Overlay click={backdropClickHandler} />}
        <SideDrawer show={sideDrawerOpen} click={drawerToggleClickHandler} />
        <Switch>
          <Route path="/" exact component={Home}></Route>
          <Route path="/career" exact component={About}></Route>
          <Route path="/case-studies" exact component={CaseStudies}></Route>
          <Route path="/case-studies/:id" component={CaseStudy}></Route>
          <Route path="/contact" exact component={Contact}></Route>
          <Route path="/credits" exact component={Credits}></Route>
          <Route path="/NewTemplate" exact component={NewTemplate}></Route>
          <Route component={Error}></Route>
        </Switch>
        <Footer />
      </div>
    </HashRouter>
  );
}

export default App;
