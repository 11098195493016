import React from "react";

function ContactDetails() {
  return (
    <section className="contact">
      <div className="container">
        <div className="row">
          <div className="order-2 order-md-1 col-lg-6 col-md-6 col-sm-12 col-xs-12 cover">
            <img
              src="http://www.duransearle.co.uk/img/contact__image.jpg"
              alt="Contact"
            />
          </div>
          <div className="offset-md-1 order-1 order-md-2  col-lg-5 col-md-5 col-sm-12 col-xs-12 d-flex align-items-center">
            <div className="section__text-block">
              <div className="section__title">Get in touch</div>
              <div className="section__text">
                Tel: 07500 151 975
                <br />
                Email:&nbsp;
                <a
                  href="mailto:info@duransearle.co.uk"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  info@duransearle.co.uk
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactDetails;
