import React, { useState } from 'react';

const PasswordPrompt = ({ onAuthenticated, fullWidth }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  // List of accepted passwords
  const acceptedPasswords = ['TRIA_Passw0rd', 'DSearlePortfolio1', 'password3']; // Replace with actual passwords

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if the entered password is in the list of accepted passwords
    if (acceptedPasswords.includes(password)) {
      localStorage.setItem('authenticated', 'true');
      onAuthenticated();
    } else {
      setError('Incorrect password');
    }
  };

  return (
    <section className={`password-overlay ${fullWidth ? 'full-width' : ''}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <div className="password-prompt">
              <h3 className="password-prompt__title">Please Enter Password</h3>
              <div>{error && <p>{error}</p>}</div>
              <form onSubmit={handleSubmit}>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter password"
                />
                <button type="submit">Submit</button>
              </form>
            </div>
          </div>
          <div className="offset-lg-1 col-lg-7 col-md-6 col-sm-12 col-xs-12">
            <div className="contact-prompt">
              <h3 className="contact-prompt__title">Want to see more?</h3>
              <span className="contact-prompt__text">
                To access the full case study, please email <a
                  href="mailto:info@duransearle.co.uk"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  info@duransearle.co.uk
                </a>.
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PasswordPrompt;
