import React from "react";

function headerBanner(props) {
  const data = props.block;
  return (
    <section
      className="headerBanner"
      style={{
        backgroundImage: `url(${data.image})`,
      }}
    >
      <div className="container d-flex">
      </div>
      <div className="headerBanner__overlay"></div>
    </section>
  );
}

export default headerBanner;
