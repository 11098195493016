import React from "react";

const ArgosSideDrawer = (props) => {
  let drawerClasses = "argos-side-drawer";
  if (props.show) {
    drawerClasses = "argos-side-drawer open";
  }

  return (
    <div className={drawerClasses}>
      <div className="argos-side-drawer__header">
        <div className="argos-side-drawer__close" onClick={props.click}>
          <i className="fas fa-times"></i>
        </div>
        <div className="hotspot-content__title">Get the look</div>
      </div>
      <div className="hotspot-content">
        <div className="hotspot-content__image-block">
          <img
            src="http://duransearle.co.uk/img/case-studies/argos-hotspot-component/argos-hotspot-component__header-banner.jpg"
            alt="argos"
          />
        </div>
        <div className="hotspot-content__text-block">
          <div className="hotspot-content__product-name">Product Name</div>
          <div className="hotspot-content__product-reviews">
            <img
              src="http://duransearle.co.uk/img/case-studies/argos-hotspot-component/argos__review-stars.png"
              alt="argos"
            />
            <span>5/5</span>
          </div>
          <div className="hotspot-content__product-price">£00.00</div>
          <div className="hotspot-content__text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Pellentesque mattis nibh turpis, ac posuere augue pulvinar sit amet.
            Sed ac urna at lectus lobortis condimentum. Pellentesque ut lorem eu
            mauris tincidunt elementum. Sed sed congue diam. Proin at eros et
            neque pharetra tristique sit amet ac dolor. <br />
            <br />
            Praesent consequat luctus augue, eget mattis ante accumsan eget.
            Cras hendrerit urna id turpis vulputate, vitae dictum mi feugiat.
            Fusce ultricies interdum tempor. Etiam sed convallis dolor.
            <ul>
              <li>Key Product Attribuite</li>
              <li>Key Product Attribuite</li>
              <li>Key Product Attribuite</li>
              <li>Key Product Attribuite</li>
              <li>Key Product Attribuite</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="hotspot__footer">
        <div className="argos-cta--primary" onClick={props.click}>
          Add to Trolley
        </div>
        <div className="argos-cta--secondary" onClick={props.click}>
          Full Details
        </div>
      </div>
    </div>
  );
};

export default ArgosSideDrawer;
