import React from "react";
import { Link } from "react-router-dom";

const SideDrawer = (props) => {
  let drawerClasses = "side-drawer";
  if (props.show) {
    drawerClasses = "side-drawer open";
  }
  return (
    <nav className={drawerClasses}>
      <div className="side-drawer__header">
        <div className="side-drawer__close" onClick={props.click}>
          <span>Close</span>
          <i className="fas fa-times"></i>
        </div>
      </div>
      <ul className="side-drawer__list">
        <li className="side-drawer__item">
          <Link
            to="/career"
            className="side-drawer__link"
            onClick={props.click}
          >
            Career
          </Link>
        </li>
        <li className="side-drawer__item">
          <Link
            to="/case-studies"
            className="side-drawer__link"
            onClick={props.click}
          >
            Case Studies
          </Link>
        </li>
        <li className="side-drawer__item">
          <Link
            to="/contact"
            className="side-drawer__link"
            onClick={props.click}
          >
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default SideDrawer;
