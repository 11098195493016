import React from "react";

function imageTriple(props) {
  const data = props.block;
  return (

    <section className={"image__triple " + data._uid}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="d-flex h-100">
              <img className="image__img--one"
                src={data.srcOne}
                alt={data.altOne}
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <img className="image__img--two"
              src={data.srcTwo}
              alt={data.altTwo}
            />

            <img className="image__img--three"
              src={data.srcThree}
              alt={data.altThree}
            />
          </div>
        </div>
      </div>
    </section>

  );
}

export default imageTriple;
