import React from "react";

function paragraph(props) {
  const data = props.block;
  const subParagraph = data.subParagraph;
  const listItem = data.listItem;

  console.log(subParagraph);

  return (
    <section className={"paragraph " + data._uid}>
      <div className="container">
        <div className="row">
          <div className="offset-lg-2 offset-md-2 col-lg-8 col-md-8 col-sm-12 col-xs-12">
            <div className="paragraph__text-block">
              {data.title ? (
                <h2 className="paragraph__title">{data.title}</h2>
              ) : null}
              <span className="paragraph__text">{data.text}</span>
            </div>

            {listItem && listItem.length > 0 && (
              <ol className="list-item">
                {listItem.map((item, i) =>
                  item ? (
                    <li key={item.id}>{item.item}</li>
                  ) : null
                )}
              </ol>
            )}



          </div>
        </div>
      </div>
    </section >

  );
}

export default paragraph;
