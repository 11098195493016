import React, { useEffect } from "react";
import ContactDetails from "../components/ContactDetails.js";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import HeroBanner from "../components/hero-banner/HeroBanner.js";

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="about">
      <HeroBanner
        heading={"Career"}
        background={
          "http://www.duransearle.co.uk/img/career__header-banner.jpg"
        }
      />

      <section className="intro">
        <div className="container">
          <div className="row">
            <p>
              I am a UX designer based on the South Coast. I've got over 15 years of experience in e-commerce user experience and web design. I've been lucky enough to work at some of the UK's largest retail brands, including Marks and Spencer, Argos, and, most recently, John Lewis.
              <br />
              <br />
              I've spent the last two years working with John Lewis & Partners, one of the UK's largest high-street retailers. I worked across different product teams to deliver improvements throughout the website, app, and in-store experiences, including product pages, checkout, my account, and user-generated content.
              <br />
              <br />
              I regularly liaised with product owners, business stakeholders, and researchers to develop and validate customer hypotheses, ensuring a data-driven and user-centred design that met customer expectations and aligned with the business goals and objectives.
              <br />
              <br />
              The following aspects of my work may be of interest to you:</p>
            <Accordion allowZeroExpanded="true">
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div className="row">
                      <div className="col-lg-11 col-md-11 col-sm-11 col-xs-12">
                        <span className="accordion__client">
                          Key Skills
                        </span>
                      </div>

                      <div className="col-lg-1 col-md-1 col-sm-1 col-xs-12">
                        <div className="accordion__chevron">
                          <i className="fas fa-chevron-down"></i>
                        </div>
                      </div>
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="accordion__text">
                    <ul>
                      <li>
                        <b>A deep understanding of e-commerce user experience and omnichannel design. </b> I've worked with some of the UK's largest high-street retailers and eCommerce companies across many different industries as a UX/UI designer. Working with product teams to ensure a seamless and consistent user experience across all touch points.
                      </li>
                      <li>
                        <b>User research experience. </b> Successfully managed the research strategies and usability testing, analysing the findings to create wireframes and visual designs to ensure the optimal solution
                        is built.
                      </li>
                      <li>
                        <b>Successful track-record managing conflicting priorities.</b> Prioritised the requests and collaborated with product owners, business stakeholders, designers, researchers and developers — whilst being a champion for the end user.
                      </li>
                      <li>
                        <b>A/B Testing.</b> Experience running A/B testing strategies to test hypotheses to ensure the optimal solution is built.
                      </li>
                      <li>
                        <b>Experience managing workshops and 3rd party companies.</b> Managed and worked closely with user research companies such as Cxpartners and Experience Solutions to deliver high quality customer-driven insight as a result of workshops, stakeholder interviews and user testing.
                      </li>
                      <li>
                        <b>Certified SCRUM master.</b> I've been a certified SRUM master since Feb 2016 but have worked in an agile environment for many years. Most recently, I worked as an Agile coach at John Lewis, helping their App’s teams apply the Agile principles more effectively, encouraging greater collaboration - promoting user-centred design that prioritises customer outcomes.
                      </li>
                    </ul>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </section >

      <section className="career__cv">
        <div className="container d-none d-sm-block">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
              <span>Client</span>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
              <span>Position</span>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
              <span>Type</span>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <Accordion allowZeroExpanded="true">
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <span className="accordion__client">
                          John Lewis &amp; Partners
                        </span>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <span className="accordion__position">
                          Senior User Experience Consultant
                        </span>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                        <span className="accordion__date">Contract</span>
                      </div>
                      <div className="col-lg-1 col-md-1 col-sm-1 col-xs-12">
                        <div className="accordion__chevron">
                          <i className="fas fa-chevron-down"></i>
                        </div>
                      </div>
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="accordion__text">
                    <p>
                      I've spent the last two years working with one of the UK's most loved high-street retailers, John Lewis & Partners. I worked across different product teams to deliver improvements throughout the website, app and in-store experiences, including product pages, checkout, my account and user-generated content.
                      <br />
                      <br />
                      I regularly liaised with product owners, business stakeholders, and researchers to develop and validate customer hypotheses, ensuring a data-driven and user-centred design that met customer expectations and aligned with the business goals and objectives.
                      <br />
                      <br />
                    </p>
                    <span className="accordion__client">Key Skills</span>
                    <ul>
                      <li>
                        Led the user experience across multiple teams, including Fashion, Product page, user-generated content and Apps.
                      </li>
                      <li>
                        Led and facilitated workshops to define and articulate the product vision and team ideation sessions with 5+ teams.
                      </li>
                      <li>
                        Worked with multiple product owners to define quarterly OKRs and product road maps.
                      </li>
                      <li>
                        Conducted a user-centred design process to identify customer-focused opportunity areas, creating a new product team comprising 8 members.
                      </li>
                      <li>
                        Successfully delivered and iterated on a new “My Review” submission journey, resulting in increased submissions and product ratings.
                      </li>
                      <li>
                        Developed & validated customer hypotheses based on user data/insights.
                      </li>
                      <li>
                        Defined and analysed A/B tests, ensuring optimal customer impact.
                      </li>
                      <li>
                        Effectively communicate design concepts, rationale, and impact to technical and non-technical stakeholders.
                      </li>
                      <li>
                        Worked with the Head of UX to promote Agile practices and empower UX designers.
                      </li>
                      <li>
                        Created detailed epics and stories for development teams.
                      </li>
                    </ul>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <span className="accordion__client">
                          Doctify
                        </span>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <span className="accordion__position">
                          Senior User Experience Consultant
                        </span>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                        <span className="accordion__date">Contract</span>
                      </div>
                      <div className="col-lg-1 col-md-1 col-sm-1 col-xs-12">
                        <div className="accordion__chevron">
                          <i className="fas fa-chevron-down"></i>
                        </div>
                      </div>
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="accordion__text">
                    <p className="accordion__client">Key Skills</p>
                    <ul>
                      <li>
                        Liaised with product owners, business directors, and developers, ensuring deliverables met user expectations and business requirements.
                      </li>
                      <li>
                        Created wireframes and designs for an end-to-end booking system.
                      </li>
                      <li>
                        Detailed clear acceptance criteria for in-house and off-shore teams.
                      </li>
                    </ul>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <span className="accordion__client">
                          Marks and Spencers
                        </span>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <span className="accordion__position">
                          Senior User Experience Consultant
                        </span>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                        <span className="accordion__date">Contract</span>
                      </div>
                      <div className="col-lg-1 col-md-1 col-sm-1 col-xs-12">
                        <div className="accordion__chevron">
                          <i className="fas fa-chevron-down"></i>
                        </div>
                      </div>
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="accordion__text">
                    <p>
                      In November 2018 I joined M&amp;S as a Senior User
                      Experience Consultant, M&amp;S is one of the UK's most
                      loved retailers and specialises in clothing, home and food
                      products.
                      <br />
                      <br />
                      Whilst at M&amp;S I worked across many different product
                      teams including, header &amp; navigation, product listing
                      page, product display page and checkout.
                      <br />
                      <br />I regularly liaised with product owners, business
                      stakeholders, and researchers in order to understand
                      business data and needs.
                      <br />
                      <br /> Working with the UI design team, I played an
                      integral part in delivering improvements to the overall
                      user experience of the M&amp;S site.
                      <br />
                      <br />
                    </p>
                    <span className="accordion__client">Key Skills</span>
                    <ul>
                      <li>
                        Created compelling wireframes and designs for each scenario.
                      </li>
                      <li>
                        Created user journey maps to ensure the experience was consistent throughout the site.
                      </li>
                      <li>
                        Worked with the product owners to run business workshops, Ensuring all the business needs were addressed and stakeholders were kept up to date.
                      </li>
                      <li>
                        Created InVision prototypes for both lab and remote usability testing.
                      </li>
                      <li>
                        Built customer-facing A/B test via Abode target to ensure the optimum solution was built.
                      </li>
                      <li>
                        Created detailed epics and stories for both in-house and off-shore development teams.
                      </li>
                      <li>
                        Provide UX support through the development process.
                      </li>
                    </ul>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <span className="accordion__client">
                          Sainsbury's Argos
                        </span>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <span className="accordion__position">
                          Senior UXA Consultant
                        </span>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                        <span className="accordion__date">Contract</span>
                      </div>
                      <div className="col-lg-1 col-md-1 col-sm-1 col-xs-12">
                        <div className="accordion__chevron">
                          <i className="fas fa-chevron-down"></i>
                        </div>
                      </div>
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="accordion__text">
                    <p>
                      In April 2017 I joined Sainsbury's Argos as a Senior UXA
                      consultant, Sainsbury's Argos is a multichannel general
                      merchandise retailer and is UK's 3rd largest eCommerce
                      company based on revenue*, boasting nearly a billion
                      online visitors and 29 million store customers every
                      year** During the 2017 black Friday campaign the site had
                      more than 800,000 visits between 21:00 and 22:00, that
                      more than 13,000 customers every minute.
                      <br />
                      <br />
                      Working within the Decidability &amp; Bespoke streams I
                      regularly liaised with product owners, business
                      stakeholders, and researchers in order to understand
                      business data and needs to improve the user experience of
                      the Sainsbury's Argos site, creating compelling wireframes
                      and designs that achieve the business goals and
                      objectives.
                      <br />
                      <br />
                      *Data based on 2017 IREU report
                      <br />
                      ** Data based on the Argos Wikipedia page.
                    </p>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <span className="accordion__client">
                          Iglu.com &amp; Planet Cruise
                        </span>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <span className="accordion__position">
                          UX/ UI Manager
                        </span>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                        <span className="accordion__date">Full-Time</span>
                      </div>
                      <div className="col-lg-1 col-md-1 col-sm-1 col-xs-12">
                        <div className="accordion__chevron">
                          <i className="fas fa-chevron-down"></i>
                        </div>
                      </div>
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="accordion__text">
                    <p>
                      At the start of 2016 I joined Iglu as the company's first
                      UX &amp; UI manager to improve of the user experience and
                      interface of all Iglu owned websites, creating compelling
                      wireframes and designs that achieve the business goals and
                      objectives.
                      <br />
                      <br />
                      Iglu.com is the UK's largest independent Cruise &amp; Ski
                      travel agent; consisting of 3 main brands Iglu Cruise,
                      Iglu Ski and Planet Cruise and has a turnover of £135
                      million. Over the last year I have helped to deliver some
                      major projects including redesigning the cruise online
                      booking platform and the Iglu Ski website both resulting
                      in increased enquires, bookings and revenue.
                      <br />
                      <br />I regularly liaised with product owners, business
                      stakeholders, and researchers in order to understand
                      business data and needs, while remaining the champion for
                      Iglu's users. I managed a team of front-end designers that
                      developed and delivered complete HTML mark up and CSS to
                      both in-house and off-shore development teams.
                      <br />
                      <br />
                      After successfully delivering online booking for Iglu
                      Cruise, Planet Cruise and the Iglu Ski redesign I decided
                      to begin a career contacting.
                      <br />
                      <br />
                    </p>
                    <p className="accordion__client">Key Skills</p>
                    <ul>
                      <li>
                        Led the optimization and redesign of the Iglu Ski
                        website across all customer contact points; resulting in
                        a 21% increase in customer enquiries YOY.
                      </li>
                      <li>
                        Successfully managed research strategies and usability
                        testing, analyzing the findings to create wireframes and
                        visual designs to ensure the optimal solution is built.
                      </li>
                      <li>
                        Worked closely with user research companies such as
                        Cxpartners to deliver high quality customer-driven
                        insight.
                      </li>
                      <li>
                        Managed a team of Front-end designers, ensuring all
                        assets were supplied to both in house and off-shore
                        development teams in line with sprint goals.
                      </li>
                      <li>
                        Successfully designed and delivered Iglu's new Online
                        Booking funnel.
                      </li>
                    </ul>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <span className="accordion__client">Wiggle.com</span>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <span className="accordion__position">
                          UX/ UI Manager / Serior UX Designer
                        </span>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                        <span className="accordion__date">Full-Time</span>
                      </div>
                      <div className="col-lg-1 col-md-1 col-sm-1 col-xs-12">
                        <div className="accordion__chevron">
                          <i className="fas fa-chevron-down"></i>
                        </div>
                      </div>
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="accordion__text">
                    <p>
                      Wiggle is the UK's number 1 online cycle retailer and has
                      grown from an annual revenue of £86 million in 2011 to a
                      revenue of £200+ million in 2015, in this time I have
                      worked on a number of projects to help Wiggle achieve this
                      growth.
                      <br />
                      <br />I initially joined Wiggle as a Technical Web
                      Designer and progressed to the role of UX &amp; Front-end
                      design manager. I was responsible for leading the
                      optimisation and development of all 14 of Wiggle's
                      international websites, apps and in-store kiosks.
                      <br />
                      <br />
                    </p>
                    <p className="accordion__client">Key Skills</p>
                    <ul>
                      <li>
                        Managed a team of Front-end and UX designers, ensuring
                        all tickets and deployments were successfully released.
                      </li>
                      <li>
                        Supported the optimization and development of the Wiggle
                        websites, for instance, the design of Wiggle's checkout,
                        which has delivered a 1.4% increase in completion rate.
                      </li>
                      <li>
                        Successfully managed the research strategies and
                        usability testing, analyzing the findings to create
                        wireframes and visual designs to ensure the optimal
                        solution is built.
                      </li>
                      <li>
                        Worked closely with user research companies such as
                        Cxpartners and Experience Solutions to deliver high
                        quality customer-driven insight.
                      </li>
                      <li>
                        Prioritised the requests and collaborated with product
                        owners, business stakeholders, designers, researchers
                        and developers — whilst being a champion for Wiggle's
                        users.
                      </li>
                    </ul>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <span className="accordion__client">
                          Hardcould &amp; Boardshop
                        </span>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <span className="accordion__position">
                          Graphic Design Manager
                        </span>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                        <span className="accordion__date">Full-Time</span>
                      </div>
                      <div className="col-lg-1 col-md-1 col-sm-1 col-xs-12">
                        <div className="accordion__chevron">
                          <i className="fas fa-chevron-down"></i>
                        </div>
                      </div>
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="accordion__text">
                    <p className="accordion__client">Key Skills</p>
                    <ul>
                      <li>Managed a team of web designers.</li>
                      <li>
                        Successfully managed the design, code and sending of 2-4
                        weekly newsletters
                      </li>
                      <li>
                        Successfully managed the design and upload of the
                        Hardcloud's and Boardshop's homepage and general look
                        and feel.
                      </li>
                      <li>
                        Designed and implemented Hardcloud's both online and
                        offline marketing strategies, for instance designing
                        brochures, promotional post cards and magazine page
                        spreads .
                      </li>
                      <li>
                        Ensured Hardcould's affiliates had the up-to-date
                        tailored artwork, banners and animated gifs to keep
                        Hardcloud's customer proposition current and consistent.
                      </li>
                      <li>
                        Ensured products were photographed and uploaded to a
                        high standard.
                      </li>
                    </ul>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </section >

      <ContactDetails />
    </div >
  );
}

export default About;
