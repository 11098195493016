import React from "react";

function fullWidth(props) {
  const data = props.block;
  return (
    <section className={"stage fullWidth " + data._uid}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="fullWidth__text-block">
              <div className="fullWidth__title">{data.title}</div>
              <div className="fullWidth__text">{data.text}</div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12">
            <img
              className="fullWidth__image"
              src={data.image}
              alt={data.title}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default fullWidth;
