import React from "react";
import { Link } from "react-router-dom";

function Status() {
  return (
    <section className="status">
      <div className="container">
        <div className="row">
          <div className="col">
            <Link to="/contact">Available for new opportunities</Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Status;
