import React from "react";

function Footer(props) {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="footer__address">
              Registered Office Address: 71-75 Shelton Street, Covent Garden,
              London, WC2H 9JQ
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
