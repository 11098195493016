import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import HeroBanner from "../components/hero-banner/HeroBanner.js";
import ContactDetails from "../components/ContactDetails.js";

function CaseStudies() {
  const CaseStudiesList = require("../data/case-studies.json");
  console.log(CaseStudiesList);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="case-studies">
      <HeroBanner
        heading={"Case Studies"}
        background={
          "http://www.duransearle.co.uk/img/case-studies__header-banner.jpg"
        }
      />
      <section className="case-studies__all">
        <div className="container">
          <div className="row">
            {CaseStudiesList.map((CaseStudiesDetail, index) => {
              return (
                <div
                  key={CaseStudiesDetail.id}
                  className="col-lg-4 col-md-6 col-sm-12 col-xs-12"
                >
                  <div className="case-studies__list">
                    <Link to={`/case-studies/${CaseStudiesDetail.slug}`}>
                      <figure className="photo__rollover">
                        <div className="photo__image">
                          <img
                            src={CaseStudiesDetail.image}
                            alt={CaseStudiesDetail.title}
                          />
                        </div>
                        <div className="photo__hover-item">
                          <i className="far fa-eye"></i>
                          <span>View</span>
                        </div>
                      </figure>
                    </Link>
                    <div className="case-studies__text-block">
                      <div className="case-studies__logo-block d-flex align-items-center">
                        <div
                          className={
                            "case-studies__logo " + CaseStudiesDetail.class
                          }
                        ></div>
                      </div>
                      <span className="case-studies__accent">
                        {CaseStudiesDetail.client}
                      </span>
                      <h3 className="case-studies__title">
                        {CaseStudiesDetail.title}
                      </h3>
                      <span className="case-studies__text">
                        {CaseStudiesDetail.description}
                      </span>
                      <Link
                        className="case-studies__link"
                        to={`/case-studies/${CaseStudiesDetail.slug}`}
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <ContactDetails />
    </div>
  );
}

export default CaseStudies;
